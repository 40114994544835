import '../css/home.css';
import { Snackbar, Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Logo from '../img/Logo.png';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Mockup from '../img/img1-01.png'
import Process from '../img/leader.png'
import Process1 from '../img/transparency.png'
import Support from '../img/support.png'
import Solution from '../img/solution.png'
import Work from '../img/about-img.png'
import Why from '../img/contact-mail.png'
import Why1 from '../img/interview.png'
import Why2 from '../img/execute.png'
import Why3 from '../img/data-processing.png'
import IMG1 from '../img/Picture2.png'
import IMG2 from '../img/Picture3.png'
import IMG3 from '../img/Picture6.png'
import IMG4 from '../img/certificate.png'
import IMG5 from '../img/Picture9.png'
import IMG6 from '../img/Picture10.png'
import S1 from '../img/img1.jpg'
import S2 from '../img/img5.jpg'
import S3 from '../img/img6.jpg'
import S4 from '../img/img2.jpg'
import S5 from '../img/img3.jpg'
import S6 from '../img/img4.jpg'
import Profile from '../img/man.png'
import { Carousel } from 'react-bootstrap';
import { faPhoneAlt, faEnvelope, faGlobe, faMap, faPhone } from "@fortawesome/free-solid-svg-icons";
import Mockup2 from '../img/mockup-1-2.png'
import mailApi from '../services/index'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import * as yup from "yup";
const Content = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState('');
    const [openAccordion, setOpenAccordion] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [hoveredCard, setHoveredCard] = useState(null)


    const handleMouseEnter = (cardIndex) => {
        setHoveredCard(cardIndex);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['home', 'about', 'services', 'contact'];
            let currentSection = '';
    
            sections.forEach((section) => {
                const element = document.getElementById(section);
                if (element) {
                    const rect = element.getBoundingClientRect();
                    if (rect.top <= 100 && rect.bottom > 100) {
                        currentSection = section;
                    }
                }
            });
    
            if (currentSection && currentSection !== activeLink) {
                setActiveLink(currentSection); // Update active link globally
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [activeLink]);
    
    const handleNavbarLinkClick = (section) => {
        setActiveLink(section); // Update active link globally
        document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', `#${section}`); // Update the URL hash
    };



    const cardStyle = {
        position: 'relative',
        width: '100%',
        maxWidth: '450px',
        margin: '10px',
        minHeight: '500px',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        overflow: 'hidden',
    };

    const hoverContentStyle = {
        display: isHovered ? 'block' : 'none',
        left: '10px',
        color: 'black',
        borderRadius: '5px',

        height: 'auto',
    };

    const cardBodyStyle = {
        padding: '20px',
        position: 'relative',
        zIndex: 1,
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToggle = (index) => {
        setOpenAccordion(openAccordion === index ? null : index);
    };



    // api
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        subject: '',
        message: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const emailFormik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone_number: "",
            subject: "",
            message: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            name: yup.string(),
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (values) => {
        const data = {
            name: values.name,
            email: values.email,
            phone: values.phone_number,
            email_subject: values.subject,
            to_mail:"enquiry@caringnri.com",
            message: values.message,
        }
        mailApi.sendEmail(data).then(onSuccess, onFailure)
    };

    const onSuccess = (response) => {
        setIsSubmitting(false);
        const message = response.data.message;
        setSnackbarMessage(message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        emailFormik.resetForm();
    };

    const onFailure = (error) => {
        setIsSubmitting(false);


        setSnackbarMessage('Failed to send email. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    };


    return (
        <div>
            <div className="">

                <Navbar
                    collapseOnSelect
                    expand="lg"
                    bg="white"
                    variant="dark"
                    fixed="top"
                    style={{
                        boxShadow: isScrolled ? '0 4px 8px rgba(0, 0, 0, 0.5)' : 'none',
                        transition: 'box-shadow 0.3s ease-in-out',
                    }}
                >
                    <Container>
                        <Navbar.Brand href="#home">
                            <a href="/">
                                <img src={Logo} width={70} alt="Logo" />
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link
                                    href="#home"
                                    style={{
                                        color: activeLink === 'home' ? '#1a2a5c' : 'black', // Customize the color
                                        fontWeight: activeLink === 'home' ? 'bold' : 'normal', // Customize active font weight
                                    }}
                                    onClick={() => handleNavbarLinkClick('home')}
                                >
                                    Home
                                </Nav.Link>
                                <Nav.Link
                                    href="#about"
                                    style={{
                                        color: activeLink === 'about' ? '#1a2a5c' : 'black',
                                        fontWeight: activeLink === 'about' ? 'bold' : 'normal',
                                    }}
                                    onClick={() => handleNavbarLinkClick('about')}
                                >
                                    About
                                </Nav.Link>
                                <Nav.Link
                                    href="#services"
                                    style={{
                                        color: activeLink === 'services' ? '#1a2a5c' : 'black',
                                        fontWeight: activeLink === 'services' ? 'bold' : 'normal',
                                    }}
                                    onClick={() => handleNavbarLinkClick('services')}
                                >
                                    Services
                                </Nav.Link>
                                <Nav.Link
                                    href="#contact"
                                    style={{
                                        color: activeLink === 'contact' ? '#1a2a5c' : 'black',
                                        fontWeight: activeLink === 'contact' ? 'bold' : 'normal',
                                    }}
                                    onClick={() => handleNavbarLinkClick('contact')}
                                >
                                    Contact
                                </Nav.Link>

                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* Hero Section */}
                <div>

                    <Carousel fade id='home'
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.9) ', bottom: '-6.75rem' }}
                        interval={3000}
                        controls={true}
                        indicators={false}
                    >
                        <Carousel.Item>
                            <img className="d-block w-100" src={IMG3} alt="First slide" />
                            <Carousel.Caption
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                    paddingLeft: '30px',
                                    paddingRight: '30px'
                                }}
                            >
                                <div className="main-headding">
                                    <span className="span aos-init aos-animate" data-aos="zoom-in-left" data-aos-duration="700">
                                        <img src="assets/img/icons/span1.png" alt="" />
                                        Welcome to
                                    </span>

                                    <h1 className='caringhead'>Caring NRI</h1>
                                    <h4 className="subtext">Your Trusted Partner for NRI Services</h4>
                                    <div className="space16"></div>
                                    <p className='subcontent'>Are you an NRI seeking reliable assistance in Tamil Nadu? At Caring NRI, we specialize in providing comprehensive services tailored to meet your needs while ensuring peace of mind.</p>
                                    <div className="space30"></div>
                                    <div className="buttons">
                                        <a className="theme-btn1" href="#contact">
                                            Get Started Now <span></span>
                                        </a>
                                    </div>

                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>

                        <Carousel.Item>
                            <img className="d-block w-100" src={IMG1} alt="Second slide" />
                            <Carousel.Caption
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                    paddingLeft: '30px',
                                    paddingRight: '30px'
                                }}
                            >
                                <div className="main-headding">
                                    <span className="span aos-init aos-animate" data-aos="zoom-in-left" data-aos-duration="700">
                                        <img src="assets/img/icons/span1.png" alt="" />
                                        Welcome to
                                    </span>
                                    <h1 className='caringhead'>Caring NRI</h1>
                                    <h4 className="subtext">Your Trusted Partner for NRI Services</h4>
                                    <div className="space16"></div>
                                    <p className='subcontent'>Are you an NRI seeking reliable assistance in Tamil Nadu? At Caring NRI, we specialize in providing comprehensive services tailored to meet your needs while ensuring peace of mind.</p>
                                    <div className="space30"></div>
                                    <div className="buttons">
                                        <a className="theme-btn1" href="#contact">
                                            Get Started Now <span></span>
                                        </a>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>


                        <Carousel.Item>
                            <img className="d-block w-100" src={IMG4} alt="Third slide" />
                            <Carousel.Caption
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    paddingTop: '20px',
                                    paddingBottom: '20px',
                                    paddingLeft: '30px',  // Add padding to the left
                                    paddingRight: '30px'  // Add padding to the right
                                }}
                            >
                                <div className="main-headding">
                                    <span className="span aos-init aos-animate" data-aos="zoom-in-left" data-aos-duration="700">
                                        <img src="assets/img/icons/span1.png" alt="" />
                                        Welcome to
                                    </span>
                                    <h1 className='caringhead'>Caring NRI</h1>
                                    <h4 className="subtext">Your Trusted Partner for NRI Services</h4>
                                    <div className="space16"></div>
                                    <p className='subcontent'>Are you an NRI seeking reliable assistance in Tamil Nadu? At Caring NRI, we specialize in providing comprehensive services tailored to meet your needs while ensuring peace of mind.</p>
                                    <div className="space30"></div>
                                    <div className="buttons">
                                        <a className="theme-btn1" href="#contact">
                                            Get Started Now <span></span>
                                        </a>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>


                    </Carousel>
                </div>

                {/* about */}


            </div>


            <section className="get-apps-style-one  my-3" style={{ overflow: 'hidden' }} id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex">
                            <div className="get-apps-style-one-content my-auto">
                                <div className="sec-title">
                                    <h2 className="aboutsecnrispan">Who We Are</h2>
                                </div>
                                <p className="whowearecontent">
                                    Caring NRI is a trusted name in delivering professional and personalized services to Non-Resident Indians (NRIs).
                                    We understand the unique challenges you face in managing responsibilities back home. Whether it's taking care of your loved ones,
                                    managing your property, or handling legal and financial matters, we are here to bridge the gap and offer you hassle-free solutions.
                                </p>

                            </div>
                        </div>


                        <div className="col-lg-6 my-4">
                            <Carousel indicators={false} interval={1000} pause="hover">
                                <Carousel.Item>
                                    <img
                                        src={S3}
                                        className="d-block w-100 img-fluid"
                                        alt="Slide 1"
                                        style={{
                                            width: '100%',
                                            height: '500px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        src={S1}
                                        className="d-block w-100 img-fluid"
                                        alt="Slide 2"
                                        style={{
                                            width: '100%',
                                            height: '500px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        src={S2}
                                        className="d-block w-100 img-fluid"
                                        alt="Slide 3"
                                        style={{
                                            width: '100%',
                                            height: '500px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        src={S5}
                                        className="d-block w-100 img-fluid"
                                        alt="Slide 4"
                                        style={{
                                            width: '100%',
                                            height: '500px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Carousel.Item>
                            </Carousel>

                        </div>


                    </div>
                </div>
            </section>



            {/* services */}

            <div className="service-area style-two" id="services">
                <h1 className='text-center text-white'>Our Services</h1>
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4" id="service2">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3"
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}>
                            <div className="card" style={{
                                display: 'flex',
                                transform: hoveredCard === 1 ? 'scale(1.025)' : 'scale(1)',
                                transition: 'transform 0.3s ease',
                                boxShadow: hoveredCard === 1 ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',


                            }}>
                                <img src={S1} className="card-img-top" alt="Image 1" />
                                <div className="card-body" style={cardBodyStyle}>
                                    <h5 className="serviceheading">Elderly Care</h5>
                                    <div
                                        style={{
                                            ...hoverContentStyle,
                                            display: hoveredCard === 1 ? 'block' : 'none',
                                        }}
                                    >
                                        <p style={{ textAlign: "justify" }}>Compassionate care for your loved ones back home</p>
                                        <p style={{ textAlign: "justify" }}>We understand how important it is for you to ensure the well-being of your elderly family members. Our Elderly Care services are designed to provide them with the utmost care and support in your absence.</p>
                                        <ul className="servicelist text-start" style={{ listStyleType: 'disc' }}>
                                            <li style={{ textAlign: "justify" }}>Personalized in-home care tailored to individual needs.</li>
                                            <li style={{ textAlign: "justify" }}>Regular health check-ups and medical assistance.</li>
                                            <li style={{ textAlign: "justify" }}>Companionship to alleviate loneliness and provide emotional support.</li>
                                            <li style={{ textAlign: "justify" }}>Assistance with daily activities such as meal preparation, medication reminders, and mobility aid.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3" id='service1'
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={handleMouseLeave}>
                            <div className="card" style={{
                                transform: hoveredCard === 2 ? 'scale(1.025)' : 'scale(1)',
                                transition: 'transform 0.3s ease',
                                boxShadow: hoveredCard === 2 ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
                                // height: hoveredCard === 6 ? '100%': "auto",

                            }}>
                                <img src={S2} alt="Image 2" />
                                <div className="card-body" style={cardBodyStyle}>
                                    <h5 className="serviceheading">Tax Management</h5>
                                    <div
                                        style={{
                                            ...hoverContentStyle,
                                            display: hoveredCard === 2 ? 'block' : 'none',
                                        }}
                                    >
                                        <p style={{ textAlign: "justify" }}>Stay compliant, effortlessly.</p>
                                        <p style={{ textAlign: "justify" }}>Navigating India’s tax system while living abroad can be overwhelming. Our Tax Management services simplify the process, ensuring your financial responsibilities are met seamlessly.</p>
                                        <ul className="servicelist text-start" style={{ listStyleType: 'disc' }}>
                                            <li style={{ textAlign: "justify" }}>Filing of income tax returns in India on your behalf.</li>
                                            <li style={{ textAlign: "justify" }}>Guidance on DTAA (Double Taxation Avoidance Agreement) benefits.</li>
                                            <li style={{ textAlign: "justify" }}>Management of your financial portfolio and investments.</li>
                                            <li style={{ textAlign: "justify" }}>Strategic planning for tax-saving opportunities.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3"
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={handleMouseLeave}>
                            <div className="card" style={{
                                transform: hoveredCard === 3 ? 'scale(1.025)' : 'scale(1)',
                                transition: 'transform 0.3s ease',
                                boxShadow: hoveredCard === 3 ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
                                // height: hoveredCard === 6 ? '100%': "auto",
                            }}>
                                <img src={S3} alt="Image 3" />
                                <div className="card-body" style={cardBodyStyle}>
                                    <h5 className="serviceheading">Property Management</h5>
                                    <div
                                        style={{
                                            ...hoverContentStyle,
                                            display: hoveredCard === 3 ? 'block' : 'none',
                                        }}
                                    >
                                        <p style={{ textAlign: "justify" }}>Hassle-free property management, wherever you are.</p>
                                        <p style={{ textAlign: "justify" }}>Managing your property from afar can be challenging. Let us handle the upkeep and ensure everything is in order while keeping you informed every step of the way.</p>
                                        <ul className="servicelist text-start" style={{ listStyleType: 'disc' }}>
                                            <li style={{ textAlign: "justify" }}>Routine property maintenance and timely repairs.</li>
                                            <li style={{ textAlign: "justify" }}>Tenant management, including rent collection and issue resolution.</li>
                                            <li style={{ textAlign: "justify" }}>Assistance with buying, selling, or renting properties.</li>
                                            <li style={{ textAlign: "justify" }}>Transparent updates through detailed reports and inspections.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4" id="service1">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3"
                            onMouseEnter={() => handleMouseEnter(4)}
                            onMouseLeave={handleMouseLeave}>
                            <div className="card" style={{
                                transform: hoveredCard === 4 ? 'scale(1.025)' : 'scale(1)',
                                transition: 'transform 0.3s ease',
                                boxShadow: hoveredCard === 4 ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
                                // height: hoveredCard === 6 ? '100%': "auto",
                            }}>
                                <img src={S4} alt="Image 4" />
                                <div className="card-body" style={cardBodyStyle}>
                                    <h5 className="serviceheading">Certification Assistance</h5>
                                    <div
                                        style={{
                                            ...hoverContentStyle,
                                            display: hoveredCard === 4 ? 'block' : 'none',
                                        }}
                                    >
                                        <p style={{ textAlign: "justify" }}>Simplifying documentation for a stress-free experience.</p>
                                        <p style={{ textAlign: "justify" }}>Acquiring necessary documents while living overseas can be time-consuming. We streamline the process to save you time and hassle.</p>
                                        <ul className="servicelist text-start" style={{ listStyleType: 'disc' }}>
                                            <li style={{ textAlign: "justify" }}>Procurement of birth, marriage, and death certificates.</li>
                                            <li style={{ textAlign: "justify" }}>Verification and attestation of educational transcripts.</li>
                                            <li style={{ textAlign: "justify" }}>Apostille and attestation services for official documents.</li>
                                            <li style={{ textAlign: "justify" }}>Guidance for seamless documentation management.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3"
                            onMouseEnter={() => handleMouseEnter(5)}
                            onMouseLeave={handleMouseLeave}>
                            <div className="card" style={{
                                transform: hoveredCard === 5 ? 'scale(1.025)' : 'scale(1)',
                                transition: 'transform 0.3s ease',
                                boxShadow: hoveredCard === 5 ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',
                                // height: hoveredCard === 6 ? '100%': "auto",
                            }}>
                                <img src={S5} className="card-img-top" alt="Image 5" />
                                <div className="card-body" style={cardBodyStyle}>
                                    <h5 className="serviceheading">Destination Management</h5>
                                    <div
                                        style={{
                                            ...hoverContentStyle,
                                            display: hoveredCard === 5 ? 'block' : 'none',
                                        }}
                                    >
                                        <p style={{ textAlign: "justify" }}>Making your visits to India smooth and memorable.</p>
                                        <p style={{ textAlign: "justify" }}>Whether it’s a personal trip or a family gathering, we ensure your visit to India is comfortable and stress-free.</p>
                                        <ul className="servicelist text-start" style={{ listStyleType: 'disc' }}>
                                            <li style={{ textAlign: "justify" }}>Personalized itinerary planning to suit your needs.</li>
                                            <li style={{ textAlign: "justify" }}>Comfortable accommodation arrangements in trusted locations.</li>
                                            <li style={{ textAlign: "justify" }}>Assistance with event planning for family functions and celebrations.</li>
                                            <li style={{ textAlign: "justify" }}>Reliable transportation and travel logistics management.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-3"
                            onMouseEnter={() => handleMouseEnter(6)}
                            onMouseLeave={handleMouseLeave}>
                            <div className="card" style={{
                                transform: hoveredCard === 6 ? 'scale(1.025)' : 'scale(1)',
                                transition: 'transform 0.3s ease',
                                boxShadow: hoveredCard === 6 ? '0 4px 8px rgba(0,0,0,0.2)' : 'none',

                                // height: hoveredCard === 6 ? '100%': "auto",
                            }}>
                                <img src={S6} alt="Image 6" />
                                <div className="card-body" style={cardBodyStyle}>
                                    <h5 className="serviceheading"> Legal Services</h5>
                                    <div
                                        style={{
                                            ...hoverContentStyle,
                                            display: hoveredCard === 6 ? 'block' : 'none',
                                        }}
                                    >
                                        <p style={{ textAlign: "justify" }}>Expert legal services for your peace of mind.</p>
                                        <p style={{ textAlign: "justify" }}>Legal matters back home require professional attention. Our experienced legal team ensures all your legal concerns are addressed effectively.</p>
                                        <ul className="servicelist text-start" style={{ listStyleType: 'disc' }}>
                                            <li style={{ textAlign: "justify" }}>Assistance with property disputes and resolution processes</li>
                                            <li style={{ textAlign: "justify" }}>Guidance on inheritance and succession planning.</li>
                                            <li style={{ textAlign: "justify" }}>Drafting and registration of legal documents such as wills and agreements.</li>
                                            <li style={{ textAlign: "justify" }}>Professional advice tailored to your specific legal needs.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>








            {/* why choose  */}
            <div class="work-process-area style-one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <div class="section-title center style-one">
                                <h1>Why Choose <span>Us</span></h1>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="single-process-box before-transprant after-transprant">
                                <div class="process-box-thumb">
                                    <img src={Process} class="img-fluid" alt="" width="70" />
                                    <div class="process-number">
                                        {/* <span>1</span> */}
                                    </div>
                                </div>
                                <div class="process-content">
                                    <h4>Reliable Team</h4>
                                    <p>Our experienced professionals ensure every service is delivered with excellence.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="single-process-box before-transprant">
                                <div class="process-box-thumb">
                                    <img src={Process1} class="img-fluid" alt="" width="70" />
                                    <div class="process-number">
                                        {/* <span>2</span> */}
                                    </div>
                                </div>
                                <div class="process-content">
                                    <h4>Transparency</h4>
                                    <p>We keep you informed every step of the way.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="single-process-box after-transprant">
                                <div class="process-box-thumb">
                                    <img src={Support} class="img-fluid" alt="" width="70" />
                                    <div class="process-number">
                                        {/* <span>3</span> */}
                                    </div>
                                </div>
                                <div class="process-content">
                                    <h4>24/7 Support</h4>
                                    <p>Round-the-clock assistance to address your queries and concerns.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="single-process-box before-transprant">
                                <div class="process-box-thumb">
                                    <img src={Solution} class="img-fluid" alt="" width="70" />
                                    <div class="process-number">
                                        {/* <span>4</span> */}
                                    </div>
                                </div>
                                <div class="process-content">
                                    <h4>Tailored Solutions</h4>
                                    <p>Customized services to meet your unique requirements.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* how it works */}
            <section className="rs-why-choose pt-120 pb-90 loaded howitbg" >
                <div className="rs-why-choose__shape-1">
                    <img src="assets/images/why-choose/chose-left-shape.png" alt="" />
                </div>
                <div className="rs-why-choose__shape-2">
                    <img src="assets/images/why-choose/chose-right-shape.png" alt="" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="rs-why-choose__thumb">
                                <img src={Work} className='img-fluid' alt="" />
                                <img className="item-2" src="assets/images/why-choose/why-choose-levft1.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 my-4">
                            <div className="rs-why-choose__content">
                                <div className="rs-section-title black">

                                    <h1 className='howitnri'>How It <span>Works</span></h1>
                                    {/* <p>Bring to the table win-win survival strategies to ensure proactive domination At the end of the day.</p> */}
                                </div>
                                <div className="rs-why-choose__item-list">
                                    <ul>
                                        <li>
                                            <div className="thumb">
                                                <img src={Why} width={50} alt="" className='img-fluid' />
                                            </div>
                                            <div className="content">
                                                <h4 className="howittitle">Contact Us</h4>
                                                <p className='howitsubtitle'>Reach out via our website or helpline to discuss your needs.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src={Why1} width={50} alt="" className='img-fluid' />
                                            </div>
                                            <div className="content">
                                                <h4 className="howittitle">Consultation</h4>
                                                <p className='howitsubtitle'>Our experts will guide you through the best solutions.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src={Why2} width={50} alt="" className='img-fluid ' />
                                            </div>
                                            <div className="content">
                                                <h4 className="howittitle">Execution</h4>
                                                <p className='howitsubtitle'>  Sit back as we handle everything on your behalf.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="thumb">
                                                <img src={Why3} width={50} alt="" className='img-fluid' />
                                            </div>
                                            <div className="content">
                                                <h4 className="howittitle">Updates
                                                </h4>
                                                <p className='howitsubtitle'>Stay informed with regular progress updates.

                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* testimonial */}


            <div className="testimonial-area style-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="section-title center style-two">
                                <h2 className="aboutsecnrispan">Testimonials</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="testi-list-1 owl-carousel owl-loaded owl-drag">
                            <Carousel indicators={false} interval={1000} pause="hover">
                                <Carousel.Item>
                                    <div className="col-12 text-center">
                                        <div className="single-testimonial-box">
                                            <img
                                                src={Profile}
                                                alt="Alex Brock"
                                                className="testi-profile-img"
                                            />
                                            <div className="testi-desc">
                                                <p>
                                                    Reliable and Trustworthy! Their team handled everything with professionalism and care, making the entire process stress-free. A true blessing for NRIs.
                                                </p>
                                            </div>

                                            <div className="testi-author">
                                                <h4>Magesh</h4>
                                                <p>United States</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="col-12 text-center">
                                        <div className="single-testimonial-box">
                                            <img
                                                src={Profile}
                                                alt="Abhishek Sharma"
                                                className="testi-profile-img"
                                            />
                                            <div className="testi-desc">
                                                <p>
                                                    Top-notch Service! From timely updates to personalized attention, they cater to every detail. I felt supported every step of the way.
                                                </p>
                                            </div>

                                            <div className="testi-author">
                                                <h4>Ramanathan</h4>
                                                <p>Singapore</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="col-12 text-center">
                                        <div className="single-testimonial-box">
                                            <img
                                                src={Profile}
                                                alt="Venkatesh Iyer"
                                                className="testi-profile-img"
                                            />
                                            <div className="testi-desc">
                                                <p>
                                                    Outstanding Experience! Their commitment to excellence and customer satisfaction sets them apart. Highly recommend for any NRI needs.
                                                </p>
                                            </div>

                                            <div className="testi-author">
                                                <h4>Sathish</h4>
                                                <p>London </p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="col-12 text-center">
                                        <div className="single-testimonial-box">
                                            <img
                                                src={Profile}
                                                alt="John D. Alexon"
                                                className="testi-profile-img"
                                            />
                                            <div className="testi-desc">
                                                <p>
                                                    Efficient and Compassionate! They understand the unique challenges NRIs face and offer tailored solutions with a caring touch. Simply outstanding!
                                                </p>
                                            </div>

                                            <div className="testi-author">
                                                <h4>Senthil Kumar</h4>
                                                <p>Dubai</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="col-12 text-center">
                                        <div className="single-testimonial-box">
                                            <img
                                                src={Profile}
                                                alt="John D. Alexon"
                                                className="testi-profile-img"
                                            />
                                            <div className="testi-desc">
                                                <p>
                                                    I approached Caring NRI to manage my property located in my native place, B. Mutlur, Cuddalore District. The team follows a well-structured system and checklist to gather all necessary details about the property and ensures it is managed seamlessly with full compliance on my behalf. This has given me immense peace of mind, allowing me to stay independent without relying on friends or relatives. Hats off to the Caring NRI team for their exceptional service!.
                                                </p>
                                            </div>

                                            <div className="testi-author">
                                                <h4>Asathullah</h4>
                                                <p>UAE</p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact */}
            <section className="cta-one sec-pad" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                            <div className="cta-one-content">
                                <div className="sec-title">
                                    <h2 className="ctasection" style={{ color: '#1a2a5c' }}>
                                        Ready to experience hassle-free NRI services? Get in touch with us now!
                                    </h2>
                                </div>

                                <div className="contact-form mt-4" style={{ backgroundColor: '#f9f9f9', padding: '30px', borderRadius: '8px' }}>

                                    <form onSubmit={emailFormik.handleSubmit}>
                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="name" className="form-label" style={{ fontWeight: 'bold', color: '#333' }}>Name</label>
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Your Name"
                                                    value={emailFormik.values.name}
                                                    onChange={emailFormik.handleChange}
                                                    required
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="email" className="form-label" style={{ fontWeight: 'bold', color: '#333' }}>Email</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Your Email"
                                                    value={emailFormik.values.email}
                                                    onChange={emailFormik.handleChange}
                                                    required
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="phone" className="form-label" style={{ fontWeight: 'bold', color: '#333' }}>Phone Number</label>
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    name="phone_number"
                                                    className="form-control"
                                                    placeholder="Your Phone Number"
                                                    value={emailFormik.values.phone_number}
                                                    onChange={emailFormik.handleChange}
                                                    required
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <label htmlFor="subject" className="form-label" style={{ fontWeight: 'bold', color: '#333' }}>Subject</label>
                                                <input
                                                    type="text"
                                                    id="subject"
                                                    name="subject"
                                                    className="form-control"
                                                    placeholder="Your Subject"
                                                    value={emailFormik.values.subject}
                                                    onChange={emailFormik.handleChange}
                                                    required
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
                                                />
                                            </div>

                                            <div className="col-12 mb-3">
                                                <label htmlFor="message" className="form-label" style={{ fontWeight: 'bold', color: '#333' }}>Message</label>
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    className="form-control"
                                                    placeholder="Your Message"
                                                    value={emailFormik.values.message}
                                                    onChange={emailFormik.handleChange}
                                                    rows="4"
                                                    required
                                                    maxLength={256}
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontSize: '16px' }}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: '#1a2a5c',
                                                borderColor: '#1a2a5c',
                                                padding: '12px 25px',
                                                borderRadius: '5px',
                                                color: '#fff',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Send Message
                                        </button>
                                    </form>
                                    <Snackbar
                                        open={snackbarOpen}
                                        autoHideDuration={1000}
                                        onClose={() => setSnackbarOpen(false)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    >
                                        <Alert
                                            onClose={() => setSnackbarOpen(false)}
                                            severity={snackbarSeverity}
                                            sx={{
                                                width: '100%',
                                                backgroundColor: '#006400',
                                                color: 'white',
                                                '& .MuiAlert-icon': {
                                                    color: 'white',
                                                },
                                                '& .MuiAlert-message': {
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            {snackbarMessage}
                                        </Alert>
                                    </Snackbar>


                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 text-center text-md-right">
                            <img src={Mockup2} alt="Awesome Image" className="img-fluid" />
                            <div className="contact-info mt-4">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="card icon-card p-3">
                                                <FontAwesomeIcon icon={faPhone} className="icon-large" />
                                            </div>
                                            <span className="contact-text ms-3">
                                                <strong> Phone:</strong> <a className='contact-text' href="tel:+918925153800">+ 91 8925153800</a>
                                            </span>

                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="card icon-card p-3">
                                                <FontAwesomeIcon icon={faEnvelope} className="icon-large" />
                                            </div>
                                            <span className="contact-text ms-3">
                                                <strong> Email:</strong> <a className='contact-text' href="mailto:enquiry@caringnri.com">enquiry@caringnri.com</a>
                                            </span>

                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <div className="d-flex align-items-center">
                                            <div className="card icon-card p-3">
                                                <FontAwesomeIcon icon={faGlobe} className="icon-large" />
                                            </div>
                                            <span className="contact-text ms-3">
                                                <strong> Website:</strong> <a className='contact-text' href="https://www.caringnri.com" target="_blank" rel="noopener noreferrer">www.caringnri.com</a>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </div>


    );
};

export default Content;
